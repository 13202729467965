import { useState } from "react";
import { useNavigate } from "react-router-dom";
import LoginModal from "../../../components/LoginModal";
import { UnauthorizedError } from "../../../errors/http_errors";
import { Administrator } from "../../../services/models/administrator";
import { LoginCredentials } from "../../../services/models/credentials";
import * as AdministratorsApi from "../../../services/network/administrator-api";
import styleUtils from "../../../assets/styles/utils.module.css";

interface AdminAuthPageProps {
    onLoginSuccessful: (admin: Administrator) => void,
}

const AdministratorAuthPage = ({onLoginSuccessful}: AdminAuthPageProps) => {
    const [errorText, setErrorText] = useState<string | null>(null);
    let navigate = useNavigate();
    const routeChange = () =>{
        let path = `cabinet`;
        navigate(path);
    }
    async function onSubmit(credentials: LoginCredentials) {
        try {
            const user = await AdministratorsApi.login(credentials);
            localStorage.setItem("user", JSON.stringify(user))
            onLoginSuccessful(user);
            routeChange();
        } catch (error) {
            if (error instanceof UnauthorizedError) {
                setErrorText(error.message);
            } else {
                alert(error);
            }
            console.error(error);
        }
    }

    return (
        <>
            <h1 className={styleUtils.flexCenter}>Авторизация администратора</h1>
            <LoginModal errorText={errorText} onSubmit={onSubmit}/>
        </>
        
);
}

export default AdministratorAuthPage;