import { ListGroup } from 'react-bootstrap';

interface ColorRangeProps {
    minValue: number,
    maxValue: number,
    colors: string[]
}

const ColorRange = ({ minValue, maxValue, colors }: ColorRangeProps) => {
    return (
        <ListGroup horizontal>
        {colors.map((color, index) => (
            <ListGroup.Item key={index} style={{background: color, color: 'white', fontSize: 12}}>
                {((index) * (maxValue - minValue) / 5).toFixed(1).toString() + " - " + ((index + 1) * (maxValue - minValue) / 5).toFixed(1).toString()}
            </ListGroup.Item>
        ))}
        </ListGroup>
    );
};

export default ColorRange;
