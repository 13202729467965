import { Button, Card } from "react-bootstrap";
import styles from "../../../assets/styles/ObserverCuratorsGrid.module.css"
import { Role } from "../../../services/models/role";

interface RoleCardProps {
    role: Role,
    onUpdateRoleClicked: (role: Role) => void,
    onDeleteRoleClicked: (role: Role) => void,
}

const RoleCard = ({role, onUpdateRoleClicked, onDeleteRoleClicked}: RoleCardProps) => {
    return(
        <Card>
            <Card.Body>
                <Card.Text className={styles.curatorNameField}>
                    <div>{role.name}</div>
                </Card.Text>
            </Card.Body>
            <Card.Footer className={styles.curatorNameField}>
                <Button
                    onClick={(e) => {
                        onUpdateRoleClicked(role);
                        e.stopPropagation();
                    }}
                >
                    Редактировать
                </Button>
                <Button
                    variant="danger"
                    onClick={(e) => {
                        onDeleteRoleClicked(role);
                        e.stopPropagation();
                    }}
                    >
                    Удалить
                </Button>
            </Card.Footer>
        </Card>
    );
}

export default RoleCard