import { Button, Card } from "react-bootstrap";
import { SubUser } from "../../../services/models/sub_user";
import styles from "../../../assets/styles/ObserverCuratorsGrid.module.css"
import { Role } from "../../../services/models/role";

interface SubUserCardProps {
    subUser: SubUser,
    roles: Role[],
    onUpdateSubUserClicked: (subUser: SubUser) => void,
    onDeleteSubUserClicked: (subUser: SubUser) => void,
}

const SubUserCard = ({subUser, roles, onUpdateSubUserClicked, onDeleteSubUserClicked}: SubUserCardProps) => {
    function findRole(roleId: string) {
        let roleName = "Роль не найдена"
        roles.forEach(role => {
            if (role.id === roleId) {
                roleName = role.name;
            }
        });
        return roleName
    }
    
    return(
        <Card>
            <Card.Body>
                <Card.Text className={styles.curatorNameField}>
                    <div>{subUser.full_name}</div>
                </Card.Text>
                <Card.Text className={styles.curatorNameField}>
                    <div>Роль: </div>
                    <div>{findRole(subUser.role)}</div>
                </Card.Text>
                <Card.Text className={styles.curatorNameField}>
                    <div>Почта:</div>
                    <a href={`mailto:${subUser?.email}`}>{subUser.email}</a>
                </Card.Text>
            </Card.Body>
            <Card.Footer className={styles.curatorNameField}>
                <Button
                    onClick={(e) => {
                        onUpdateSubUserClicked(subUser);
                        e.stopPropagation();
                    }}
                >
                    Редактировать
                </Button>
                <Button
                    variant="danger"
                    onClick={(e) => {
                        onDeleteSubUserClicked(subUser);
                        e.stopPropagation();
                    }}
                    >
                    Удалить
                </Button>
            </Card.Footer>
        </Card>
    );
}

export default SubUserCard