import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Administrator } from "../../services/models/administrator";
import { SubUser } from "../../services/models/sub_user";
import * as AdministratorsApi from "../../services/network/administrator-api";

function isAdministrator(obj: any): obj is Administrator {
    return obj.role === undefined 
}

function isSubUser(obj: any): obj is SubUser {
    return obj.role !== undefined 
}

interface NavBarLoggedInViewProps {
    user: Administrator,
    onLogoutSuccessful: () => void,
}

const NavBarLoggedInView = ({ user, onLogoutSuccessful}: NavBarLoggedInViewProps) => {
    let navigate = useNavigate();
    const routeChange = (path: string) =>{
        navigate(path);
    }
    
    const goHome = () => {
        if (isAdministrator(user)) {
            navigate("/administrator/cabinet");
        }
        if (isSubUser(user)) {
            navigate("/user/cabinet")
        }
    }
    
    async function logout() {
        try {
            await AdministratorsApi.logout();
            onLogoutSuccessful();
            routeChange("/");
        } catch (error) {
            console.error(error);
            alert(error);
        }
    }
    
    return (
        <>
            <Button onClick={goHome}>
                Главная
            </Button>
            <Button onClick={logout}>
                Выход
            </Button>
        </>
    );
}

export default NavBarLoggedInView;
