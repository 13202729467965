import { Container } from "react-bootstrap";
import styles from "../../../assets/styles/ProjectPage.module.css"
import CreateUpdatePollPageView from "./CreateUpdatePollPageView";
import { useParams } from "react-router-dom";
import NotFoundPage from "../../NotFound/NotFoundPage";

interface CreateUpdatePollPageProps{
    createNew: boolean
}

const CreateUpdatePollPage = ({createNew}: CreateUpdatePollPageProps) => {
    const {pollTemplateId, sectorId, pollId} = useParams();
    return (
        <Container className={styles.projectPage}>
            <>
                {(pollTemplateId && sectorId) ? 
                    <CreateUpdatePollPageView
                        pollTemplateId={pollTemplateId}
                        sectorId={sectorId}
                        pollId={pollId}
                        createNew={createNew}
                    /> :
                    <NotFoundPage/>
                }
            </>
        </Container>
    );
};

export default CreateUpdatePollPage;