import { FormEvent, useEffect, useState } from "react";
import { PollTemplate as PollTemplateModel } from "../../../services/models/poll_template";
import { useNavigate } from "react-router-dom";
import * as PollTemplateApi from "../../../services/network/poll_template-api";
import * as SubUserApi from "../../../services/network/sub_user-api";
import * as SectorApi from "../../../services/network/sector-api";
import { Button, Card, Dropdown, DropdownButton, Form, Spinner } from "react-bootstrap";
import styles from "../../../assets/styles/CreateUpdatePoll.module.css";
import stylesUtils from "../../../assets/styles/utils.module.css"
import { SubUser } from "../../../services/models/sub_user";
import { FaRegCheckCircle, FaRegCircle,  } from "react-icons/fa";
import { Sector } from "../../../services/models/sector";

interface CreateUpdatePollTemplatePageViewProps {
    pollTemplateId?: string,
    createNew: boolean
}

const CreateUpdatePollTemplatePageView = ({pollTemplateId, createNew}: CreateUpdatePollTemplatePageViewProps) => {
    const [pollTemplate, setPollTemplate] = useState<PollTemplateModel>(
        {
            id: "",
            allowed_sub_users: [],
            title: "",
            description: "",
            modules: [
                {
                    allowed_sub_users:[],
                    title: "",
                    description: "",
                    values: [
                        {
                            allowed_sub_users: [],
                            title: "",
                            value: 0.
                        },
                    ]
                },
            ],
        }
    );
    const [sectors, setSectors] = useState<Sector[]>([]);
    const [subUsers, setSubUsers] = useState<SubUser[]>([]);
    const [subUsersToShow, setSubUsersToShow] = useState<SubUser[]>([]);
    const [pollTemplateLoading, setPollTemplateLoading] = useState(true);
    const [showPollTemplateLoadingError, setShowPollTemplateLoadingError] = useState(false);

    const [subUsersNameFilter, setSubUsersNameFilter] = useState("");

    useEffect(() => {
        async function loadPollTemplate() {
            if (pollTemplateId) {
                try {
                    setShowPollTemplateLoadingError(false);
                    setPollTemplateLoading(true);
                    const pollTemplateData = await PollTemplateApi.fetchPollTemplate({pollTemplateId: pollTemplateId});
                    const subUsersData = await SubUserApi.getSubUsers();
                    const sectorsData = await SectorApi.fetchSectors({poll_template_id: pollTemplateId});
                    setSubUsers(subUsersData);
                    setSubUsersToShow(subUsersData);
                    setPollTemplate(pollTemplateData);
                    setSectors(sectorsData);
                } catch (error) {
                    console.error(error);
                    setShowPollTemplateLoadingError(true);
                } finally {
                    setPollTemplateLoading(false);
                }
            } else {
                try {
                    setShowPollTemplateLoadingError(false);
                    setPollTemplateLoading(true);
                    const subUsersData = await SubUserApi.getSubUsers();
                    setSubUsers(subUsersData);
                    setSubUsersToShow(subUsersData);
                } catch (error) {
                    console.error(error);
                    setShowPollTemplateLoadingError(true);
                } finally {
                    setPollTemplateLoading(false);
                }
            }
        }
        loadPollTemplate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const filter = (e: any) => {
        const keyword = e.target.value;

        if (keyword !== "") {
            const filteredArray = subUsers.filter((user) => {
                return user.full_name.toLowerCase().startsWith(keyword.toLowerCase());
            })
            setSubUsersToShow(filteredArray);
        } else {
            setSubUsersToShow(subUsers);
        }

        setSubUsersNameFilter(keyword);
    }
    
    let navigate = useNavigate();

    const handlePollTemplateChange = (field: keyof PollTemplateModel, value: string) => {
        setPollTemplate({ ...pollTemplate, [field]: value });
      };
    
    const handleModuleChange = (index: number, field: keyof PollTemplateModel['modules'][0], value: string) => {
        const updatedModules = pollTemplate.modules;
        const allowedSubUsers = pollTemplate.allowed_sub_users;
        if (field !== 'values' && field !== "allowed_sub_users") {
            updatedModules[index][field] = value;
        }
        if (field === "allowed_sub_users") {
            if (!updatedModules[index].allowed_sub_users) {
                updatedModules[index].allowed_sub_users = [];
              }
              if (!updatedModules[index].allowed_sub_users.includes(value)) {
                updatedModules[index].allowed_sub_users.push(value);
                for (let valueIndex = 0; valueIndex < updatedModules[index].values.length; valueIndex++) {
                    if (!updatedModules[index].values[valueIndex].allowed_sub_users.includes(value)) {
                        updatedModules[index].values[valueIndex].allowed_sub_users.push(value);
                    }
                }
                if (!pollTemplate.allowed_sub_users.includes(value)) {
                    pollTemplate.allowed_sub_users.push(value);
                }
              } else {
                updatedModules[index].allowed_sub_users =
                updatedModules[index].allowed_sub_users.filter((id) => id !== value);
                for (let valueIndex = 0; valueIndex < updatedModules[index].values.length; valueIndex++) {
                    updatedModules[index].values[valueIndex].allowed_sub_users = 
                    updatedModules[index].values[valueIndex].allowed_sub_users.filter((id) => id !== value);
                }
                let inOtherModules = false;
                for (let i = 0; i < updatedModules.length; i++) {
                    if (updatedModules[i].allowed_sub_users.includes(value)) {
                        inOtherModules = true;
                        break;
                    }
                }
                if (!inOtherModules) {
                    pollTemplate.allowed_sub_users = allowedSubUsers.filter(
                        (id) => id !== value
                    );
                }
              }
        }
        setPollTemplate({ ...pollTemplate, modules: updatedModules });
    };

    const handleAddModule = () => {
        const updatedModules = pollTemplate.modules;
        updatedModules.push({
            allowed_sub_users: [],
            title: '',
            description: '',
            values: [{
                allowed_sub_users: [],
                title: "",
                value: 0.
            }],
        })
        setPollTemplate({...pollTemplate, modules: updatedModules});
      };
    
      const handleRemoveModule = (index: number) => {
        const updatedModules = pollTemplate.modules;
        updatedModules.splice(index, 1);
        setPollTemplate({ ...pollTemplate, modules: updatedModules });
      };
    
    const handleValueChange = (moduleIndex: number, valueIndex: number , field: keyof PollTemplateModel['modules'][0]['values'][0], value: string|number) => {
        const updatedModules = pollTemplate.modules;
        if (field === 'title' &&  typeof value === "string") {
            updatedModules[moduleIndex].values[valueIndex][field] = value;
        } else if (field === 'value' &&  typeof value === "number") {
            updatedModules[moduleIndex].values[valueIndex][field] = value;
        } else if (field === "allowed_sub_users" && typeof value === "string") {
            if (!updatedModules[moduleIndex].values[valueIndex].allowed_sub_users) {
                updatedModules[moduleIndex].values[valueIndex].allowed_sub_users = [];
            }
            if (!updatedModules[moduleIndex].values[valueIndex].allowed_sub_users.includes(value)){
                updatedModules[moduleIndex].values[valueIndex].allowed_sub_users.push(value);
                if(!updatedModules[moduleIndex].allowed_sub_users.includes(value)){
                    updatedModules[moduleIndex].allowed_sub_users.push(value);
                }
                if (!pollTemplate.allowed_sub_users.includes(value)) {
                    pollTemplate.allowed_sub_users.push(value);
                }
            }
            else {
                updatedModules[moduleIndex].values[valueIndex].allowed_sub_users = 
                updatedModules[moduleIndex].values[valueIndex].allowed_sub_users.filter((id) => id !== value)
                let inOtherValues = false;
                for (let i = 0; i < updatedModules[moduleIndex].values.length; i++){
                    if (updatedModules[moduleIndex].values[i].allowed_sub_users.includes(value)){
                        inOtherValues = true;
                        break;
                    }
                }
                if (!inOtherValues) {
                    updatedModules[moduleIndex].allowed_sub_users = updatedModules[moduleIndex].allowed_sub_users.filter(
                        (id) => id !== value
                    );
                }
                let inOtherModules = false;
                for (let i = 0; i < updatedModules.length; i++) {
                    if (updatedModules[i].allowed_sub_users.includes(value)) {
                        inOtherModules = true;
                        break;
                    }
                }
                if (!inOtherModules) {
                    pollTemplate.allowed_sub_users = pollTemplate.allowed_sub_users.filter(
                        (id) => id !== value
                    );
                }
            }
        }
        setPollTemplate({ ...pollTemplate, modules: updatedModules });
    };

    const handleAddValue = (moduleIndex: number) => {
        const updatedModules = pollTemplate.modules;
        updatedModules[moduleIndex].values.push({allowed_sub_users: updatedModules[moduleIndex].allowed_sub_users, title: '', value: 0, });
        setPollTemplate({ ...pollTemplate, modules: updatedModules });
    };
    
    const handleRemoveValue = (moduleIndex: number, valueIndex: number) => {
        const updatedModules = pollTemplate.modules;
        updatedModules[moduleIndex].values.splice(valueIndex, 1);
        setPollTemplate({ ...pollTemplate, modules: updatedModules });
    };

    async function handleSubmit(event: FormEvent) {
        event.preventDefault()
        try {
            if (!createNew && pollTemplateId) {
                let pollResponse: PollTemplateModel;
                pollResponse = await PollTemplateApi.updatePollTemplate(pollTemplateId, pollTemplate);
                setPollTemplate(pollResponse);
            } else if (createNew && !pollTemplateId) {
                let pollResponse: PollTemplateModel;
                pollResponse = await PollTemplateApi.createPollTemplate(pollTemplate)
                setPollTemplate(pollResponse);
            } else if (createNew && pollTemplateId) {
                let pollResponse: PollTemplateModel;
                pollResponse = await PollTemplateApi.createPollTemplate(pollTemplate)
                setPollTemplate(pollResponse);
                sectors.forEach(async sector => {
                    await SectorApi.createSector({name: sector.name, poll_template: pollResponse.id});
                });
            }
        } catch (error) {
            console.error(error);
            alert(error);
        } finally {
            navigate(-1);
        }
    }
    
    return (
        <>
            <div className={`${stylesUtils.width100} ${stylesUtils.flexLeft}`}>
              <Button
                    className={`mb-1`}
                    onClick={() => navigate(-1)}
                    >
                    Назад
                </Button>
            </div>
            {pollTemplateLoading && <Spinner animation='border' variant='primary' />}
            {showPollTemplateLoadingError && <p>Something went wrong. Please refresh the page.</p>}
            {!pollTemplateLoading && !showPollTemplateLoadingError && 
                <Form onSubmit={handleSubmit}>
                    {createNew?
                        <h1>Создание шаблона</h1>
                    :   
                        <h1>Редактирование шаблона</h1>
                    }
                    <Form.Group className="mb-2" controlId={`poll-title`}>
                        <Form.Label>Название</Form.Label>
                        <Form.Control
                            type="text"
                            value={pollTemplate.title}
                            onChange={(e) => 
                                handlePollTemplateChange('title', e.target.value)
                            }
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId={`poll-description`}>
                        <Form.Label>Описание</Form.Label>
                        <Form.Control
                            type="textarea"
                            value={pollTemplate.description}
                            onChange={(e) => 
                                handlePollTemplateChange('description', e.target.value)
                            }
                        />
                    </Form.Group>
                    {pollTemplate.modules.map((module, moduleIndex) => (
                        <Card key={moduleIndex} className="mb-3">
                            <Card.Header>Модуль {moduleIndex + 1}</Card.Header>
                            <Card.Body>
                                <Form.Group className="mb-2" controlId={`module-allowed_sub_users-${moduleIndex}`}>
                                    <Form.Label>Разрешённые пользователи</Form.Label>
                                    <DropdownButton
                                        id={`allowed-users-dropdown-${moduleIndex}`}
                                        title="Выбрать пользователей"
                                        autoClose="outside"
                                    >
                                        <input
                                            type="search"
                                            value={subUsersNameFilter}
                                            onChange={filter}
                                            className={styles.search}
                                            placeholder="Поиск по ФИО">
                                        </input>
                                        {subUsersToShow.map((subUser) => (
                                        <Dropdown.Item
                                            key={subUser.id}
                                            onClick={() => handleModuleChange(moduleIndex, 'allowed_sub_users', subUser.id)}
                                            className={styles.subUserItem}
                                            >
                                            {subUser.full_name}
                                            {module.allowed_sub_users?.includes(subUser.id) 
                                            ?  <FaRegCheckCircle/>
                                            : <FaRegCircle/>
                                            }
                                        </Dropdown.Item>
                                        ))}
                                    </DropdownButton>
                                </Form.Group>
                                <Form.Group className="mb-2" controlId={`module-name-${moduleIndex}`}>
                                    <Form.Label>Название</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={module.title}
                                        onChange={(e) => 
                                            handleModuleChange(
                                                moduleIndex, 
                                                'title', 
                                                e.target.value
                                            )
                                        }
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId={`module-description-${moduleIndex}`}>
                                    <Form.Label>Описание</Form.Label>
                                    <Form.Control
                                        type="textarea"
                                        value={module.description}
                                        onChange={(e) => 
                                            handleModuleChange(
                                                moduleIndex, 
                                                'description', 
                                                e.target.value
                                            )
                                        }
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId={`module-values-${moduleIndex}`}>
                                    <h3>Значения</h3>
                                    {module.values.map((value, valueIndex) => (
                                        <div key={valueIndex}>
                                            <Form.Group className={`mb-3`} controlId={`value-name-${moduleIndex}-${valueIndex}`}>
                                                <Form.Label>Название</Form.Label>
                                                <div className={styles.value}>    
                                                    <Form.Control
                                                        type="text"
                                                        value={value.title}
                                                        onChange={(e) => 
                                                            handleValueChange(
                                                                moduleIndex, 
                                                                valueIndex, 
                                                                'title', 
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                    <DropdownButton
                                                        id={`allowed-users-dropdown-${moduleIndex}-${valueIndex}`}
                                                        title="Доступы"
                                                        autoClose="outside"
                                                        className={`ms-2`}
                                                    >
                                                        <input
                                                            type="search"
                                                            value={subUsersNameFilter}
                                                            onChange={filter}
                                                            className={styles.search}
                                                            placeholder="Поиск по ФИО">
                                                        </input>
                                                        {subUsersToShow.map((subUser) => (
                                                        <Dropdown.Item
                                                            key={subUser.id}
                                                            onClick={() => handleValueChange(moduleIndex, valueIndex, 'allowed_sub_users', subUser.id)}
                                                            className={styles.subUserItem}
                                                            >
                                                            {subUser.full_name}
                                                            {value.allowed_sub_users?.includes(subUser.id) 
                                                            ?  <FaRegCheckCircle/>
                                                            : <FaRegCircle/>
                                                            }
                                                        </Dropdown.Item>
                                                        ))}
                                                    </DropdownButton>
                                                    <Button
                                                        className="ms-2"
                                                        variant="danger"
                                                        onClick={() => handleRemoveValue(moduleIndex, valueIndex)}
                                                    >
                                                        Удалить
                                                    </Button>
                                                </div>
                                            </Form.Group>
                                        </div>
                                    ))}
                                    <div className={stylesUtils.flexCenter}>
                                        <Button
                                            onClick={() => handleAddValue(moduleIndex)}
                                            >
                                            Добавить значение
                                        </Button>
                                    </div>
                                </Form.Group>
                            </Card.Body>
                            {pollTemplate.modules.length > 1 && (
                                <Card.Footer>
                                <Button variant="danger" onClick={() => handleRemoveModule(moduleIndex)}>Удалить модуль</Button>
                                </Card.Footer>
                            )}
                        </Card>
                    ))}
                    <div className={`mb-3 ${stylesUtils.flexCenterSpaceAround}`}>
                        <Button onClick={handleAddModule}>Добавить модуль</Button>
                        
                    </div>
                    <div className={stylesUtils.flexCenter}>
                        <Button type="submit">Сохранить</Button>
                    </div>
                </Form>
            }
        </>
    );
}

export default CreateUpdatePollTemplatePageView;