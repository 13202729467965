import { FormEvent, useEffect, useRef, useState } from "react";
import styles from "../../../assets/styles/ProjectInfoPage.module.css";
import stylesUtils from "../../../assets/styles/utils.module.css";
import { Poll as PollModel } from "../../../services/models/poll";
import { useNavigate } from "react-router-dom";
import { Button, Card, Form, Spinner } from "react-bootstrap";
import * as PollApi from "../../../services/network/poll-api";
import React from "react";

interface FillPollPageViewProps {
    pollId: string,
}

const FillPollPageView = ({pollId}: FillPollPageViewProps) => {
    const [poll, setPoll] = useState<PollModel|null>(null);
    const [pollLoading, setPollLoading] = useState(true);
    const [showPollLoadingError, setShowPollLoadingError] = useState(false);

    const inputRefs = useRef<any>({});

    const refocusInput = (name: any) => {
        if (inputRefs.current[name]) {
          inputRefs.current[name].current.focus();
          console.log(inputRefs.current[name])
        }
    };

    let navigate = useNavigate();

    useEffect(() => {
        async function loadPoll() {
            try {
                setShowPollLoadingError(false);
                setPollLoading(true);
                const pollData = await PollApi.fetchPoll({pollId: pollId});
                setPoll(pollData);
                for (let i = 0; i < pollData.modules.length; i++){
                    for(let j = 0; j < pollData.modules[i].values.length; j++){
                        inputRefs.current[`value-value-${i.toString()}-${j.toString()}`] = React.createRef();
                    }
                }
            } catch (error) {
                console.error(error);
                setShowPollLoadingError(true);
            } finally {
                setPollLoading(false);
            }
        }
        loadPoll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    const handleValueChange = (moduleIndex: number, valueIndex: number , value: number) => {
        if (poll) {
            try {
                const updatedModules = poll.modules;
                const moduleObject = updatedModules.at(moduleIndex);
                const valueObject = moduleObject?.values.at(valueIndex);
                if (valueObject) {
                    valueObject.value = value;
                } else {
                    Error("Value not found")
                }
                setPoll({ ...poll, modules: updatedModules });
                
            } catch (error) {
                console.log(error)
            }
        }
        setTimeout(() => {
            refocusInput(`value-value-${moduleIndex.toString()}-${valueIndex.toString()}`);
          }, 10); 
    };

    const checkShowModule = (moduleIndex: number) => {
        if (poll){
            try {
                const module = poll.modules[moduleIndex];
                for( let i = 0; i < module.values.length; i++) {
                    if (module.values[i].title !== "Undefined") {
                        return true;
                    }
                }
            } catch (error) {
                console.error(error);
                alert(error);
            }
        }
        return false;
    }

    async function handleSubmit(event: FormEvent) {
        event.preventDefault()
        try {
            if (poll) {
                let pollResponse: PollModel;
                pollResponse = await PollApi.updatePoll(pollId, poll);
                setPoll(pollResponse);
            }
        } catch (error) {
            console.error(error);
            alert(error);
        } finally {
            navigate(-1);
        }
    }

    const ModuleList = () => {
        return (
            <Form onSubmit={handleSubmit}>
                {poll?.modules.map((module, moduleIndex)=> (
                    <div key={moduleIndex}>
                    {checkShowModule(moduleIndex) &&
                        <Card  className={`mb-3 ${stylesUtils.flexTextCard}`}>
                            <Card.Header>{module.title}</Card.Header>
                            <Card.Body>
                                {module.values.map((value, valueIndex) => (
                                    <>
                                        {value.title !== "Undefined" &&
                                            <Form.Group 
                                                key={`value-value-${moduleIndex.toString()}-${valueIndex.toString()}`} 
                                                controlId={`value-value-${moduleIndex.toString()}-${valueIndex.toString()}`}
                                                className={styles.valueRow}
                                                >
                                                <div>{value.title}</div>
                                                <Form.Control
                                                    className={styles.valueInput}
                                                    type={"number"}
                                                    step="0.1"
                                                    value={value.value}
                                                    ref={inputRefs.current[`value-value-${moduleIndex.toString()}-${valueIndex.toString()}`]}
                                                    onChange={(event) => {
                                                        handleValueChange(moduleIndex, valueIndex, Number(event.target.value));
                                                    }}
                                                />
                                            </Form.Group>
                                        }
                                    </>
                                    ))
                                }
                            </Card.Body>
                        </Card>
                    }
                    </div>
                    )
                )
                }
                <Button className="mb-1" type="submit">
                    Сохранить
                </Button>
            </Form>
        );
    };

    return(
        <>
            <div className={`${stylesUtils.width100} ${stylesUtils.flexLeft}`}>
              <Button
                    className={`mb-1`}
                    onClick={() => navigate(-1)}
                    >
                    Назад
                </Button>
            </div>
            {pollLoading && <Spinner animation='border' variant='primary' />}
            {showPollLoadingError && <p>Something went wrong. Please refresh the page.</p>}
            {!pollLoading && !showPollLoadingError && 
                <>
                    <h1 className="mb-3">
                        {poll?.title}
                    </h1>
                    <ModuleList/>
                </>
            }
        </>
    );
};

export default FillPollPageView;