import { useState } from "react";
import { useNavigate } from "react-router-dom";
import LoginModal from "../../../components/LoginModal";
import { UnauthorizedError } from "../../../errors/http_errors";
import { SubUser } from "../../../services/models/sub_user";
import { LoginCredentials } from "../../../services/models/credentials";
import * as SubUserApi from "../../../services/network/sub_user-api";
import styleUtils from "../../../assets/styles/utils.module.css";

interface SubUserAuthPageProps {
    onLoginSuccessful: (sub_user: SubUser) => void,
}

const SubUserAuthPage = ({onLoginSuccessful}: SubUserAuthPageProps) => {
    const [errorText, setErrorText] = useState<string | null>(null);
    let navigate = useNavigate();
    const routeChange = () =>{
        let path = `cabinet`;
        navigate(path);
    }
    async function onSubmit(credentials: LoginCredentials) {
        try {
            const subUser = await SubUserApi.loginSubUser(credentials);
            localStorage.setItem("user", JSON.stringify(subUser))
            onLoginSuccessful(subUser);
            routeChange();
        } catch (error) {
            if (error instanceof UnauthorizedError) {
                setErrorText(error.message);
            } else {
                alert(error);
            }
            console.error(error);
        }
    }

    return (
        <>
            <h1 className={styleUtils.flexCenter}>Авторизация пользователя</h1>
            <LoginModal errorText={errorText} onSubmit={onSubmit}/>
        </>
        
);
}

export default SubUserAuthPage;