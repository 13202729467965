import { Button, Form, Spinner, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import styles from "../../../assets/styles/ProjectsPage.module.css";
import stylesUtils from "../../../assets/styles/utils.module.css";
import { useEffect, useState } from "react";
import { Poll } from "../../../services/models/poll";
import * as PollTemplateApi from "../../../services/network/poll_template-api";
import * as PollApi from "../../../services/network/poll-api";
import * as SectorApi from "../../../services/network/sector-api";
import { Sector } from "../../../services/models/sector";
import { PollTemplate } from "../../../services/models/poll_template";
import { Line } from 'react-chartjs-2';
import { Chart, registerables } from "chart.js/auto";
import ColorRange from "../../../components/ColorRange";

Chart.register(...registerables);


interface PollStatisicsPageViewProps{
    pollTemplateId: string,
}


const PollStatisicsPageView = ({pollTemplateId}:PollStatisicsPageViewProps) => {
    interface SectorPolls{
        sector: Sector,
        polls: Poll[],
    }
    const [statistic, setStatistic] = useState<SectorPolls[]>([]);
    const [pollTemplate, setPollTemplate] = useState<PollTemplate|null>(null)
    const [statisticsLoading, setStatisticsLoading] = useState(true);
    const [showStatisticsLoadingError, setShowStatisticsLoadingError] = useState(false);

    const defaultPollsNumber = 2;
    const [pollsNumberToShow, setPollsNumberToShow] = useState(defaultPollsNumber);
    const [maxNumberOfPolls, setMaxNumberOfPolls] = useState(defaultPollsNumber);
    const [moduleIndex, setModuleIndex] = useState(-1);
    const [valueIndex, setValueIndex] = useState(-1);

    const [tags, setTags] = useState<string[]>([]);
    const [tableHeader, setTableHeader] = useState<string[]>(["Регион"]);
    const [tableBody, setTableBody] = useState<string[][]>([[]]);
    const [tableBodyData, setTableBodyData] = useState<string[][]>([[]]);

    const chartAverageOptions = {
        responsive: true,
        plugins: {
          legend: {
            position: "top" as const,
          },
          title: {
            display: true,
            text: "Рейтинг по годам",
          },
        },
        scales:{
            x:{
                offset: true,
            },
            y:{
                offset: true,
            }
        }
      }
    const [chartData, setChartData] = useState<any>({
        labels: ["2024", "2025", "2026"],
        datasets: [{
            label: 'Среднее по секторам',
            data: [0., 0.4, 0.2],
            borderColor: 'rgb(255, 99, 132)',
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
            fill: false,
        }],
    });
    const [chartDataToShow, setChartDataToShow] = useState<any>({
        labels: ["2024", "2025", "2026"],
        datasets: [],
    });
    const [chartDataUnique, setChartDataUnique] = useState<any>({
        labels: ["2024", "2025", "2026"],
        datasets: [],
    });
    const [chartDataUniqueToShow, setChartDataUniqueToShow] = useState<any>({
        labels: ["2024", "2025", "2026"],
        datasets: [],
    });
    const [sectorIndex, setSectorIndex] = useState(-1);

    const [minRating, setMinRating] = useState(0);
    const [maxRating, setMaxRating] = useState(0);
    const colors = ["#ba550d", "#c8752d", "#d59546", "#e0b45e", "#225931"];

    const calculateModuleRating = (module: { values: { value: number}[]}) => {
        return module.values.reduce((sum, value) => sum + value.value, 0);
    };

    const calculatePollRating = (poll: {modules: { values: { value: number}[]}[]}) => {
        return poll.modules.reduce((sum, module) => sum + calculateModuleRating(module), 0);
    };

    const calculatePollStatistic = (statisticData:SectorPolls[], tagsData: string[]) => {
        let maxRating = 0;
        let minRating = 0;
        let tableBodyData: string[][] = [];
        let chartData: number[] = new Array(tagsData.length).fill(0);
        let counters: number[] = new Array(tagsData.length).fill(0);
        for(let i = 0; i < statisticData.length; i++) {
            let row = [statisticData[i].sector.name];
            for(let k = 0; k < tagsData.length; k++) {
                const poll = statisticData[i].polls.find(poll => poll.tag === tagsData[k]);
                if(!poll){
                    row.push('-');
                } else {
                    const rating = calculatePollRating(poll);
                    if (rating > maxRating) {
                        maxRating = rating;
                    }
                    if (rating < minRating) {
                        minRating = rating;
                    }
                    row.push(rating.toString());
                    chartData[k] += rating;
                    counters[k] += 1;
                }
            }
            tableBodyData.push(row);
        }
        chartData = chartData.map((element, index) => {
            element = element / counters[index];
            return element;
        });
        setMaxRating(maxRating);
        setMinRating(minRating);
        setTableBodyData(tableBodyData);
        setTableBody(tableBodyData.map((row) => row.slice(0, pollsNumberToShow+1)));
        setChartData({
            labels: [...tagsData].reverse(),
            datasets: [{
                label: 'Среднее по секторам',
                data: [...chartData].reverse(),
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
                fill: false,
            }],
        });
        setChartDataToShow({
            labels: [...tagsData].reverse().slice(-pollsNumberToShow),
            datasets: [{
                label: 'Среднее по секторам',
                data: [...chartData].reverse().slice(-pollsNumberToShow),
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
                fill: false,
            }],
        });
    };

    const calculateModuleStatistic = (statisticData:SectorPolls[], tagsData: string[], moduleIndex:number) => {
        let maxRating = 0;
        let minRating = 0;
        let tableBodyData: string[][] = [];
        let chartData: number[] = new Array(tagsData.length).fill(0);
        let counters: number[] = new Array(tagsData.length).fill(0);
        for(let i = 0; i < statisticData.length; i++) {
            let row = [statisticData[i].sector.name];
            for(let k = 0; k < tagsData.length; k++) {
                const poll = statisticData[i].polls.find(poll => poll.tag === tagsData[k]);
                if(!poll){
                    row.push('-')
                } else {
                    const rating = calculateModuleRating(poll.modules[moduleIndex]);
                    if (rating > maxRating) {
                        maxRating = rating;
                    }
                    if (rating < minRating) {
                        minRating = rating;
                    }
                    row.push(rating.toString());
                    chartData[k] += rating;
                    counters[k] += 1;
                }
            }
            tableBodyData.push(row);
        }
        chartData = chartData.map((element, index) => {
            element = element / counters[index];
            return element;
        });
        setMaxRating(maxRating);
        setMinRating(minRating);
        setTableBodyData(tableBodyData);
        setTableBody(tableBodyData.map((row) => row.slice(0, pollsNumberToShow+1)));
        setChartData({
            labels: [...tagsData].reverse(),
            datasets: [{
                label: 'Среднее по секторам',
                data: [...chartData].reverse(),
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
                fill: false,
            }],
        });
        setChartDataToShow({
            labels: [...tagsData].reverse().slice(-pollsNumberToShow),
            datasets: [{
                label: 'Среднее по секторам',
                data: [...chartData].reverse().slice(-pollsNumberToShow),
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
                fill: false,
            }],
        });
    }

    const calculateValueStatistic = (statisticData:SectorPolls[], tagsData: string[], moduleIndex: number, valueIndex: number) => {
        let maxRating = 0;
        let minRating = 0;
        let tableBodyData: string[][] = [];
        let chartData: number[] = new Array(tagsData.length).fill(0);
        let counters: number[] = new Array(tagsData.length).fill(0);
        for(let i = 0; i < statisticData.length; i++) {
            let row = [statisticData[i].sector.name];
            for(let k = 0; k < tagsData.length; k++) {
                const poll = statisticData[i].polls.find(poll => poll.tag === tagsData[k]);
                if(!poll){
                    row.push('-')
                } else {
                    const rating = poll.modules[moduleIndex].values[valueIndex].value;
                    if (rating > maxRating) {
                        maxRating = rating;
                    }
                    if (rating < minRating) {
                        minRating = rating;
                    }
                    row.push(rating.toString());
                    chartData[k] += rating;
                    counters[k] += 1;
                }
            }
            tableBodyData.push(row);
        }
        chartData = chartData.map((element, index) => {
            element = element / counters[index];
            return element;
        });
        setMaxRating(maxRating);
        setMinRating(minRating);
        setTableBodyData(tableBodyData);
        setTableBody(tableBodyData.map((row) => row.slice(0, pollsNumberToShow+1)));
        setChartData({
            labels: [...tagsData].reverse(),
            datasets: [{
                label: 'Среднее по секторам',
                data: [...chartData].reverse(),
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
                fill: false,
            }],
        });
        setChartDataToShow({
            labels: [...tagsData].reverse().slice(-pollsNumberToShow),
            datasets: [{
                label: 'Среднее по секторам',
                data: [...chartData].reverse().slice(-pollsNumberToShow),
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
                fill: false,
            }],
        });
    }

    const calculatePollStatisticUnique = (sectorIndex: number, statisticData:SectorPolls[], tagsData: string[]) => {
        let chartData: number[] = new Array(tagsData.length).fill(0);
        for(let k = 0; k < tagsData.length; k++) {
            const poll = statisticData[sectorIndex].polls.find(poll => poll.tag === tagsData[k]);
            if(poll){
                const rating = calculatePollRating(poll)
                chartData[k] += rating;
            }
        }
        setChartDataUnique({
            labels: [...tagsData].reverse(),
            datasets: [{
                label: 'Значение по сектору',
                data: [...chartData].reverse(),
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
                fill: false,
            }],
        });
        setChartDataUniqueToShow({
            labels: [...tagsData].reverse().slice(-pollsNumberToShow),
            datasets: [{
                label: 'Значение по сектору',
                data: [...chartData].reverse().slice(-pollsNumberToShow),
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
                fill: false,
            }],
        });
    };

    const calculateModuleStatisticUnique = (sectorIndex: number, statisticData:SectorPolls[], tagsData: string[], moduleIndex:number) => {
        let chartData: number[] = new Array(tagsData.length).fill(0);
        for(let k = 0; k < tagsData.length; k++) {
            const poll = statisticData[sectorIndex].polls.find(poll => poll.tag === tagsData[k]);
            if(poll){
                const rating = calculateModuleRating(poll.modules[moduleIndex]);
                chartData[k] += rating;
            }
        }
        setChartDataUnique({
            labels: [...tagsData].reverse(),
            datasets: [{
                label: 'Значение по сектору',
                data: [...chartData].reverse(),
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
                fill: false,
            }],
        });
        setChartDataUniqueToShow({
            labels: [...tagsData].reverse().slice(-pollsNumberToShow),
            datasets: [{
                label: 'Значение по сектору',
                data: [...chartData].reverse().slice(-pollsNumberToShow),
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
                fill: false,
            }],
        });
    }

    const calculateValueStatisticUnique = (sectorIndex: number, statisticData:SectorPolls[], tagsData: string[], moduleIndex: number, valueIndex: number) => {
        let chartData: number[] = new Array(tagsData.length).fill(0);
        for(let k = 0; k < tagsData.length; k++) {
            const poll = statisticData[sectorIndex].polls.find(poll => poll.tag === tagsData[k]);
            if(poll) {
                const rating = poll.modules[moduleIndex].values[valueIndex].value;
                chartData[k] += rating;
            }
        }
        setChartDataUnique({
            labels: [...tagsData].reverse(),
            datasets: [{
                label: 'Значение по сектору',
                data: [...chartData].reverse(),
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
                fill: false,
            }],
        });
        setChartDataUniqueToShow({
            labels: [...tagsData].reverse().slice(-pollsNumberToShow),
            datasets: [{
                label: 'Значение по сектору',
                data: [...chartData].reverse().slice(-pollsNumberToShow),
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
                fill: false,
            }],
        });
    }

    const recalculateStatistic = (moduleIndex: number, valueIndex: number) => {
        if (moduleIndex === -1) {
            calculatePollStatistic(statistic, tags);
            if (sectorIndex !== -1) {
                calculatePollStatisticUnique(sectorIndex, statistic, tags)
            }
        } else if (valueIndex === -1) {
            calculateModuleStatistic(statistic, tags, moduleIndex);
            if (sectorIndex !== -1) {
                calculateModuleStatisticUnique(sectorIndex, statistic, tags, moduleIndex)
            }
        } else {
            calculateValueStatistic(statistic, tags, moduleIndex, valueIndex);
            if (sectorIndex !== -1) {
                calculateValueStatisticUnique(sectorIndex, statistic, tags, moduleIndex, valueIndex)
            }
        }
    }

    useEffect(() => {
        async function loadPolls() {
            try {
                setShowStatisticsLoadingError(false);
                setStatisticsLoading(true);
                let tagsData: string[] = [];
                let statisticData: SectorPolls[] = [];
                const pollTemplateData = await PollTemplateApi.fetchPollTemplate({pollTemplateId:pollTemplateId})
                const sectorData = await SectorApi.fetchSectors({poll_template_id: pollTemplateId});
                for(let i = 0; i < sectorData.length; i++){
                    const pollsData = await PollApi.fetchPolls({sector_id:sectorData[i].id});
                    if (pollsData.length > maxNumberOfPolls) {
                        setMaxNumberOfPolls(pollsData.length);
                    }
                    for(let k = 0; k < pollsData.length; k++) {
                        if(!tagsData.includes(pollsData[k].tag)){
                            tagsData.push(pollsData[k].tag)
                        }
                    }
                    statisticData.push({sector: sectorData[i], polls: pollsData.reverse()});
                }
                tagsData = tagsData.sort().reverse();
                if(tagsData.length === 0){
                    tagsData = ["-", "-"]
                }
                if(tagsData.length === 1){
                    tagsData.push("-")
                }
                setPollTemplate(pollTemplateData);
                setTags(tagsData);
                setStatistic(statisticData);
                setTableHeader([...tableHeader, ...tagsData.slice(0, defaultPollsNumber)]);
                calculatePollStatistic(statisticData, tagsData);
            } catch (error) {
                console.error(error);
                setShowStatisticsLoadingError(true);
            } finally {
                setStatisticsLoading(false);
            }
        };
        loadPolls();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    let navigate = useNavigate();

    const handlePollsNumberChange = (value: number) => {
        try {
            setPollsNumberToShow(value);
            setTableHeader(["Регион", ...tags.slice(0, value)]);
            setTableBody(tableBodyData.map((row) => row.slice(0, value+1)));
            setChartDataToShow({
                labels: [...tags].reverse().slice(-value),
                datasets: [{
                    label: 'Среднее по секторам',
                    data: [...chartData.datasets[0].data].slice(-value),
                    borderColor: 'rgb(255, 99, 132)',
                    backgroundColor: 'rgba(255, 99, 132, 0.5)',
                    fill: false,
                }],
            });
            setChartDataUniqueToShow({
                labels: [...tags].reverse().slice(-value),
                datasets: [{
                    label: 'Значение по сектору',
                    data: [...chartDataUnique.datasets[0].data].slice(-value),
                    borderColor: 'rgb(255, 99, 132)',
                    backgroundColor: 'rgba(255, 99, 132, 0.5)',
                    fill: false,
                }],
            });
        } catch (error) {
            console.log(error)
        }
    };

    const handleChartTypeChange = (sectorIndex: number) => {
        try {
            if (sectorIndex === -1) {
                setSectorIndex(-1);
            } else {
                if (moduleIndex === -1) {
                    calculatePollStatisticUnique(sectorIndex, statistic, tags);
                } else if (valueIndex === -1) {
                    calculateModuleStatisticUnique(sectorIndex, statistic, tags, moduleIndex);
                } else {
                    calculateValueStatisticUnique(sectorIndex, statistic, tags, moduleIndex, valueIndex);
                }
                setSectorIndex(sectorIndex);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const getColorByValue = (value: string) => {
        let index: number = parseInt((parseFloat(value) / (maxRating - minRating) * colors.length).toFixed());
        if (index >= colors.length) {
            index = colors.length - 1;
        }
        console.log(index)
        return colors[index];
    };

    const tableHead = 
        <thead>
            <tr>
                {tableHeader.map(header => (
                    <th>
                        {header}
                    </th>
                ))}
            </tr>
        </thead>

    const sectorsRows =
        <tbody>
            {tableBody.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                        {row.map((value, valueIndex) => (
                            <>
                            {valueIndex !== 0 ?
                            <td style={{background: getColorByValue(value), color: 'white'}}>
                                {parseFloat(value).toFixed(1)}
                            </td>:
                            <td>{value}</td>
                            }
                            </>
                        ))}
                    </tr> 
            ))}
        </tbody>
    
    return (
        <>
            <div className={`${stylesUtils.width100} ${stylesUtils.flexLeft}`}>
              <Button
                    className={`mb-1`}
                    onClick={() => navigate(-1)}
                    >
                    Назад
                </Button>
            </div>
            {statisticsLoading && <Spinner animation='border' variant='primary' />}
            {showStatisticsLoadingError && <p>Something went wrong. Please refresh the page.</p>}
            {!statisticsLoading && !showStatisticsLoadingError && pollTemplate &&
                <>
                <div className={`mb-2 ${styles.statisticsControl}`}>
                    <Form.Select
                        className="mb-2"
                        id={`module`}
                        title="Выбрать модуль"
                        onChange={(event) => {
                            setModuleIndex(Number(event.target.value));
                            recalculateStatistic(Number(event.target.value), -1);
                        }}
                    >
                        <option
                            key={-1}
                            value={-1}
                            >
                            Общий рейтинг
                        </option>
                        {pollTemplate.modules.map((module, moduleIndex) => (
                        <option
                            key={moduleIndex}
                            value={moduleIndex}
                            >
                            {module.title}
                        </option>
                        ))}
                    </Form.Select>
                    {moduleIndex!==-1 ?
                        <Form.Select
                            className="mb-2"
                            id={`value`}
                            title="Выбрать значение"
                            disabled={moduleIndex===-1}
                            onChange={(event) => {
                                setValueIndex(Number(event.target.value));
                                recalculateStatistic(moduleIndex, Number(event.target.value));
                            }}
                            >
                            <option
                                key={-1}
                                value={-1}
                                >
                                Весь модуль
                            </option>
                            {pollTemplate.modules[moduleIndex].values.map((value, valueIndex) => (
                                <option
                                    key={valueIndex}
                                    value={valueIndex}
                                    >
                                    {value.title}
                                </option>
                            ))}
                        </Form.Select> :
                        <Form.Select className="mb-2" disabled={true}>
                            <option>Все значения</option>
                        </Form.Select>
                    }
                    <Form.Group key={`pollsNumber-input`} className={stylesUtils.flexCenterSpaceAround}>
                        <Form.Label>Количество опросов</Form.Label>
                        <Form.Control
                            type="number"
                            min={2}
                            max={maxNumberOfPolls}
                            step="1"
                            value={pollsNumberToShow}
                            onChange={(event) => {
                                handlePollsNumberChange(Number(event.target.value));
                            }}
                        />
                    </Form.Group>
                    <Form.Select
                        className="mb-2"
                        id={`sector`}
                        title="Выбрать сектор"
                        onChange={(event) => {
                            handleChartTypeChange(Number(event.target.value));
                        }}
                        >
                        <option
                            key={-1}
                            value={-1}
                            >
                            Среднее значение
                        </option>
                        {statistic.map((sector, sectorIndex) => (
                            <option
                                key={sectorIndex}
                                value={sectorIndex}
                                >
                                {sector.sector.name}
                            </option>
                        ))}
                    </Form.Select>
                </div>
                {sectorIndex === -1 ?
                <Line
                    options={chartAverageOptions}
                    data={chartDataToShow}
                /> :
                <Line
                    options={chartAverageOptions}
                    data={chartDataUniqueToShow}
                />
                }
                <Table bordered hover className={styles.tableStyle}>
                    {tableHead}
                    {statistic.length > 0 ? 
                        sectorsRows
                        :
                        <tbody>
                            <tr>
                                <td colSpan={3}>
                                    Сектора ещё не добавлены
                                </td>
                            </tr>
                        </tbody>
                    }
                </Table>
                <ColorRange minValue={minRating} maxValue={maxRating} colors={colors}/>
                </>
            }
        </>
    );
};

export default PollStatisicsPageView;