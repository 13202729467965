import { Alert, Button, Container, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { LoginCredentials } from "../services/models/credentials";
import styleUtils from "../assets/styles/utils.module.css";
import styles from "../assets/styles/AuthPage.module.css";
import TextInputField from "./AdminComponents/form/TextInputField";

interface LoginModalProps {
    errorText: string | null,
    onSubmit: (credentials: LoginCredentials) => void,
}

const LoginModal = ({errorText, onSubmit}: LoginModalProps) => {
    const {register, handleSubmit, formState: {errors, isSubmitting}} = useForm<LoginCredentials>();
    
    return (
        <>
            <Container className={styleUtils.flexBlockCenter}>
                {errorText &&
                    <Alert variant="danger">
                        {errorText}
                    </Alert>
                }
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <TextInputField 
                        name="login"
                        label="Логин"
                        type="text"
                        placeholder="Логин"
                        register={register}
                        registerOptions={{required: "Required"}}
                        error={errors.login}
                        className={styles.inputField}
                    />
                    <TextInputField 
                        name="password"
                        label="Пароль"
                        type="password"
                        placeholder="Пароль"
                        register={register}
                        registerOptions={{required: "Required"}}
                        error={errors.login}
                        className={styles.inputField}
                    />
                    <Button
                        type="submit"
                        disabled={isSubmitting}
                        className={styleUtils.flexSizeButton}>
                        Войти
                    </Button>
                </Form>
            </Container>
        </>
    );
}

export default LoginModal;