import { Navigate } from "react-router-dom";
import { Administrator } from "../services/models/administrator";
import { SubUser } from "../services/models/sub_user";

function isAdministrator(obj: any): obj is Administrator {
    return obj.role === undefined 
}

function isSubUser(obj: any): obj is SubUser {
    return obj.role !== undefined 
}

interface ProtectedRouteProps {
    user: Administrator | SubUser | null,
    children: JSX.Element,
}

//TODO setup check if right type of user
export function AdministratorProtectedRoute({ user, children }: ProtectedRouteProps) {
    if (!user) {
        return <Navigate to="/" replace />;
    }
    else if (!isAdministrator(user)) {
        return <Navigate to="/" replace />;
    }
    return children;
}

export function SubUserProtectedRoute({ user, children }: ProtectedRouteProps) {
    if (!user) {
        return <Navigate to="/" replace />;
    }
    else if (!isSubUser(user)) {
        return <Navigate to="/" replace />;
    }
    return children;
}