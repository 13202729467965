import { Container } from "react-bootstrap";
import styles from "../../../assets/styles/ProjectPage.module.css"
import PollsPageView from "./PollsPageView";
import { useParams } from "react-router-dom";
import NotFoundPage from "../../NotFound/NotFoundPage";

const PollsPage = () => {
    const {pollTemplateId, sectorId} = useParams();
    return (
        <Container className={styles.projectPage}>
            <>
                {(pollTemplateId && sectorId) ?
                <PollsPageView
                    pollTemplateId={pollTemplateId}
                    sectorId={sectorId}
                />:
                <NotFoundPage/>}
                
            </>
        </Container>
    );
};

export default PollsPage;