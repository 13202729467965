import { Container } from "react-bootstrap";
import styles from "../../../assets/styles/ProjectPage.module.css"
import CreateUpdatePollTemplatePageView from "./CreateUpdatePollTemplatePageView";
import { useParams } from "react-router-dom";

interface CreateUpdatePollPageProps {
    createNew: boolean
}

const CreateUpdatePollTemplatePage = ({createNew}: CreateUpdatePollPageProps) => {
    const {pollTemplateId} = useParams();
    return (
        <Container className={styles.projectPage}>
            <>
                <CreateUpdatePollTemplatePageView
                    pollTemplateId={pollTemplateId}
                    createNew={createNew}
                />
            </>
        </Container>
    );
};

export default CreateUpdatePollTemplatePage;