import { Container } from "react-bootstrap";
import styles from "../../../assets/styles/ProjectPage.module.css"
import SubUsersPageView from "./SubUsersPageView";

const SubUsersPage = () => {
    return (
        <Container className={styles.projectPage}>
            <>
                <SubUsersPageView/>
            </>
        </Container>
    );
};

export default SubUsersPage;