export default function authToken() {
    const userItem = localStorage.getItem('user')
    if (userItem){
      const user = JSON.parse(userItem);
      if (user && user.access_token) {
        return 'Bearer ' + user.access_token;
      } else {
        return "";
      }
    } else {
      return "";
    }
  }