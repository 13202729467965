import { Container } from "react-bootstrap";
import styles from "../../../assets/styles/ProjectPage.module.css"
import SectorsPageView from "./SectorsPageView";
import { useParams } from "react-router-dom";
import NotFoundPage from "../../NotFound/NotFoundPage";

const SectorsPage = () => {
    const {pollTemplateId} = useParams();
    return (
        <Container className={styles.projectPage}>
            <>
                {pollTemplateId ?
                    <SectorsPageView
                        pollTemplateId={pollTemplateId}
                    /> :
                    <NotFoundPage/>
                }
            </>
        </Container>
    );
};

export default SectorsPage;