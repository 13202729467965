import { Container } from "react-bootstrap";
import styles from "../../../assets/styles/ProjectPage.module.css"
import StatisticsPageView from "./StatisticsPageView";

const StatisticsPage = () => {
    return (
        <Container className={styles.projectPage}>
            <StatisticsPageView/>
        </Container>
    );
};

export default StatisticsPage;