import { useParams } from "react-router-dom";
import { Container } from "react-bootstrap";
import styles from "../../../assets/styles/ProjectPage.module.css"
import NotFoundPage from "../../NotFound/NotFoundPage";
import FillPollPageView from "./FillPollPageView";

const SubUserFillPollPage = () => {
    const {pollId} = useParams();
    return (
        <Container className={styles.projectPage}>
            <>
            { (pollId)
                ? <FillPollPageView pollId={pollId}/>
                : <NotFoundPage/>
            }
            </>
        </Container>
    );
};

export default SubUserFillPollPage;