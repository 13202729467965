import { Button, Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import TextInputField from "../../../components/AdminComponents/form/TextInputField";
import { Role } from "../../../services/models/role";
import * as RoleApi from "../../../services/network/role-api";

interface AddEditRoleDialogProps {
    roleToEdit?: Role,
    onDismiss: () => void,
    onRoleSaved: (role: Role) => void,
}

const AddEditRoleDialog = ({roleToEdit, onDismiss, onRoleSaved}: AddEditRoleDialogProps) => {
    interface RoleFormInput{
        name: string,
    }

    const { register, handleSubmit, formState: {errors, isSubmitting} } = useForm<RoleFormInput>({
        defaultValues: {
            name: roleToEdit?.name || "",
        }
    });

    async function onSubmit(input: RoleFormInput) {
        try {
            let roleResponse: Role;
            if (roleToEdit) {
                roleResponse = await RoleApi.updateRole(roleToEdit.id, input);
            } else {
                roleResponse = await RoleApi.createRole(input);
            }
            onRoleSaved(roleResponse);
        } catch (error) {
            console.error(error);
            alert(error);
        }
    }
    
    return(
        <Modal show onHide={onDismiss}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {roleToEdit ? "Редактировать роль" : "Добавить роль"}
                </Modal.Title>
            </Modal.Header> 
            <Modal.Body>
                <Form id="addEditRoleForm" onSubmit={handleSubmit(onSubmit)}>
                    <TextInputField
                        name="name"
                        label="Название"
                        type="text"
                        placeholder="Роль"
                        register={register}
                        registerOptions={{required: "Required"}}
                        error={errors.name}
                    />
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button 
                    type="submit"
                    form="addEditRoleForm"
                    disabled={isSubmitting}
                    >
                    Сохранить
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default AddEditRoleDialog;