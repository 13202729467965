import { Container } from "react-bootstrap";
import styles from "../../../assets/styles/ProjectPage.module.css"
import PollsPageView from "./PollsPageView";

const SubUserPollsPage = () => {
    return (
        <Container className={styles.projectPage}>
            <>
                <PollsPageView/>
            </>
        </Container>
    );
};

export default SubUserPollsPage;