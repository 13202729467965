import { Button, Container } from "react-bootstrap";
import styles from "../../../assets/styles/AuthPage.module.css";
import stylesUtils from "../../../assets/styles/utils.module.css";
import { Link } from "react-router-dom";

const SubUserCabinetPage = () => {
    return (
        <Container className={styles.authPage}>
            <Link to="polls">
                <Button className={`mb-4 ${stylesUtils.flexSizeButton} ${stylesUtils.flexCenter}` }>
                    Опросы
                </Button>
            </Link>
        </Container>
    )
}

export default SubUserCabinetPage;