import { Container } from "react-bootstrap";
import styles from "../../../assets/styles/ProjectPage.module.css"
import RolesPageView from "./RolesPageView";

const RolesPage = () => {
    return (
        <Container className={styles.projectPage}>
            <>
                <RolesPageView/>
            </>
        </Container>
    );
};

export default RolesPage;