import { useEffect, useState } from "react";
import { Role } from "../../../services/models/role";
import * as RoleApi from "../../../services/network/role-api";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import stylesUtils from "../../../assets/styles/utils.module.css";
import styles from "../../../assets/styles/TeacherCuratorsGrid.module.css"
import { useNavigate } from "react-router-dom";
import RoleCard from "./RoleCard";
import { FaPlus } from "react-icons/fa";
import AddEditRoleDialog from "./AddEditRoleDialog";

const RolePageView = () => {
    const [roles, setRoles] = useState<Role[]>([]);
    const [rolesLoading, setRolesLoading] = useState(true);
    const [showRolesLoadingError, setShowRolesLoadingError] = useState(false);

    const [showRoleForm, setShowRoleForm] = useState(false);
    const [roleToEdit, setRoleToEdit] = useState<Role|null>(null);

    useEffect(() => {
        async function loadRoles() {
            try {
                setShowRolesLoadingError(false);
                setRolesLoading(true);
                const rolesData = await RoleApi.fetchRoles();
                setRoles(rolesData);
            } catch (error) {
                console.error(error);
                setShowRolesLoadingError(true);
            } finally {
                setRolesLoading(false);
            }
        }
        loadRoles();
    }, []);

    async function deleteRole(role: Role) {
        try {
            await RoleApi.deleteRole(role.id);
            setRoles(roles.filter(existingRole => existingRole.id !== role.id));
        } catch (error) {
            console.error(error);
            alert(error);
        }
    }

    let navigate = useNavigate();

    const RolesGrid =
        <Row xs={1} md={1} xl={1} className={`g-4 ${styles.projectsGrid}`}>
            {
                roles.map(role => (
                    <Col key={role.id}>
                        <RoleCard 
                            role={role} 
                            onUpdateRoleClicked={setRoleToEdit}
                            onDeleteRoleClicked={deleteRole}
                        />
                    </Col>
                ))
            }
        </Row>

    return (
        <>
            <div className={`${stylesUtils.width100} ${stylesUtils.flexLeft}`}>
                <Button
                    className={`mb-1`}
                    onClick={() => navigate(-1)}
                    >
                    Назад
                </Button>
            </div>
            <h1>Роли</h1>
            <Button
                className={`mb-4  ${stylesUtils.flexCenter}`}
                onClick={() => setShowRoleForm(true)}>
                <FaPlus />
                Добавить Роль
            </Button>
            {rolesLoading && <Spinner animation='border' variant='primary' />}
            {showRolesLoadingError && <p>Something went wrong. Please refresh the page.</p>}
            {!rolesLoading && !showRolesLoadingError && 
                <>
                    {
                        roles.length > 0
                        ? RolesGrid
                        : <p>Вы ещё не добавили роли</p>
                    }
                </>
            }
            { showRoleForm &&
                <AddEditRoleDialog
                    onDismiss={() => setShowRoleForm(false)}
                    onRoleSaved={(newRole) => {
                        console.log(newRole)
                        setRoles([...roles, newRole]);
                        setShowRoleForm(false);
                    }}
                />
                }
                { roleToEdit &&
                <AddEditRoleDialog
                    roleToEdit={roleToEdit}
                    onDismiss={() => setRoleToEdit(null)}
                    onRoleSaved={(updatedRole) => {
                        setRoles(roles.map(existingRole => existingRole.id === updatedRole.id ? updatedRole : existingRole));
                        setRoleToEdit(null);
                        }
                    }
                />
                }
        </>
    );
};

export default RolePageView;