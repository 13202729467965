import { Card } from "react-bootstrap";
import { MdDelete, MdContentCopy } from "react-icons/md";
import { PollTemplate as PollTemplateModel } from "../services/models/poll_template";
import styles from "../assets/styles/Project.module.css";
import styleUtils from "../assets/styles/utils.module.css";

interface PollTemplateProps {
    pollTemplate: PollTemplateModel,
    onCopyPollTemplateClicked: (pollTemplate: PollTemplateModel) => void,
    onPollTemplateClicked: (pollTemplate: PollTemplateModel) => void,
    onDeletePollTemplateClicked: (pollTemplate: PollTemplateModel) => void,
    className?: string,
};

const PollTemplate = ({ pollTemplate, onCopyPollTemplateClicked, onPollTemplateClicked, onDeletePollTemplateClicked, className }: PollTemplateProps) => {
    const {
        title,
    } = pollTemplate;

    return (
        <Card
            className={`${styles.projectCard} ${className}`}
            onClick={() => onPollTemplateClicked(pollTemplate)}
            >
            <Card.Body className={styles.cardBody}>
                <Card.Title className={styleUtils.flexCenterSpaceBetween}>
                    {title}
                    <div>
                        <MdContentCopy
                            className="ms-2 text-muted ms-rights"
                            onClick={(e) => {
                                onCopyPollTemplateClicked(pollTemplate);
                                e.stopPropagation();
                            }}
                        />
                        <MdDelete
                            className="ms-2 text-muted ms-right"
                            onClick={(e) => {
                                onDeletePollTemplateClicked(pollTemplate);
                                e.stopPropagation();
                            }}
                        />
                    </div>
                </Card.Title>
            </Card.Body>
        </Card>
    );
};

export default PollTemplate;