import stylesUtils from "../../../assets/styles/utils.module.css";
import { Poll as PollModel } from "../../../services/models/poll";
import { useNavigate } from "react-router-dom";
import { Button, Card, Spinner } from "react-bootstrap";
import * as PollApi from "../../../services/network/poll-api";
import { useEffect, useState } from "react";

interface FillPollPageViewProps {
    pollId: string,
}

const PollResultPageView = ({pollId}: FillPollPageViewProps) => {
    const [poll, setPoll] = useState<PollModel|null>(null);
    const [pollLoading, setPollLoading] = useState(true);
    const [showPollLoadingError, setShowPollLoadingError] = useState(false);
    
    let navigate = useNavigate();

    useEffect(() => {
        async function loadPoll() {
            try {
                setShowPollLoadingError(false);
                setPollLoading(true);
                const pollData = await PollApi.fetchPoll({pollId: pollId});
                setPoll(pollData);
            } catch (error) {
                console.error(error);
                setShowPollLoadingError(true);
            } finally {
                setPollLoading(false);
            }
        }
        loadPoll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const calculateModuleRating = (module: { values: { value: number}[]}) => {
        return module.values.reduce((sum, value) => sum + value.value, 0);
    };

    const calculateTotalRating = () => {
        if (poll) {
            return poll.modules.reduce((totalSum, module) => totalSum + calculateModuleRating(module), 0);
        } 
        return 0;
    }

    const ModuleList = () => {
        return (
                <>
                {poll?.modules.map((module, moduleIndex)=> (
                    <Card className={`mb-3 ${stylesUtils.flexTextCard}`} key={moduleIndex}>
                        <Card.Header>{module.title}</Card.Header>
                        <Card.Body>
                            Рейтинг: {calculateModuleRating(module).toFixed(1)}
                        </Card.Body>
                    </Card>
                    )
                )
                }
                <Card className={`mb-3 ${stylesUtils.flexTextCard}`}>
                    <Card.Header>
                        Итоговый
                    </Card.Header>
                    <Card.Body>
                        Рейтинг: {calculateTotalRating().toFixed(1)}
                    </Card.Body>
                </Card>
                </>
        );
    };

    return(
        <>
            <div className={`${stylesUtils.width100} ${stylesUtils.flexLeft}`}>
              <Button
                    className={`mb-1`}
                    onClick={() => navigate(-1)}
                    >
                    Назад
                </Button>
            </div>
            {pollLoading && <Spinner animation='border' variant='primary' />}
            {showPollLoadingError && <p>Something went wrong. Please refresh the page.</p>}
            {!pollLoading && !showPollLoadingError && 
                <>
                    <h1 className="mb-3">
                        {poll?.title}
                    </h1>
                    <ModuleList/>
                </>
            }
        </>
    );
}

export default PollResultPageView;