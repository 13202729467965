import { Button, Col, Row, Spinner } from "react-bootstrap";
import styles from "../../../assets/styles/ProjectsPage.module.css";
import stylesUtils from "../../../assets/styles/utils.module.css";
import { Poll as PollModel } from "../../../services/models/poll";
import * as PollApi from "../../../services/network/poll-api";
import * as SectorApi from "../../../services/network/sector-api";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PollForSubUser from "../../../components/PollForSubUser";
import { Sector } from "../../../services/models/sector";

const PollsPageView = () => {
    interface PollWithSector {
        poll: PollModel,
        sector: Sector
    }
    const [pollsWithSectors, setPollsWithSectors] = useState<PollWithSector[]>([]);
    const [pollsLoading, setPollsLoading] = useState(true);
    const [showPollsLoadingError, setShowPollsLoadingError] = useState(false);

    useEffect(() => {
        async function loadPolls() {
            try {
                setShowPollsLoadingError(false);
                setPollsLoading(true);
                const pollsWithSectorsData: PollWithSector[] = []
                const pollsData = await PollApi.fetchPollsForSubUser();
                for(let i = 0; i < pollsData.length; i++) {
                    const sectorData = await SectorApi.fetchSector(pollsData[i].sector);
                    pollsWithSectorsData.push({poll: pollsData[i], sector: sectorData})
                }
                setPollsWithSectors(pollsWithSectorsData);
            } catch (error) {
                console.error(error);
                setShowPollsLoadingError(true);
            } finally {
                setPollsLoading(false);
            }
        };
        loadPolls();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let navigate = useNavigate();

    const goToFillPoll = (poll: PollModel) => {
        let path = `${poll.id}`;
        navigate(path);
    }

    const pollsGrid =
        <Row xs={1} md={1} xl={1} className={`g-4 ${styles.projectsGrid}`}>
            {pollsWithSectors.map(pollWithSector => (
                <Col key={pollWithSector.poll.id}>
                    <PollForSubUser
                        poll={pollWithSector.poll}
                        sectorName={pollWithSector.sector.name}
                        onFillPollClicked={goToFillPoll}
                        className={styles.project}
                    />
                </Col>
            ))}
        </Row>
    return(
        <>
            <div className={`${stylesUtils.width100} ${stylesUtils.flexLeft}`}>
                <Button
                    className={`mb-1`}
                    onClick={() => navigate(-1)}
                    >
                    Назад
                </Button>
            </div>
            <h1>Опросы</h1>
            {pollsLoading && <Spinner animation='border' variant='primary' />}
            {showPollsLoadingError && <p>Something went wrong. Please refresh the page.</p>}
            {!pollsLoading && !showPollsLoadingError && 
                <>
                {
                    pollsWithSectors.length > 0
                    ? pollsGrid
                    : <p>Опросы ещё не добавлены</p>
                }
                </>
            }
        </>
    );
};

export default PollsPageView;