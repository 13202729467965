import { useParams } from "react-router-dom";
import { Container } from "react-bootstrap";
import styles from "../../../assets/styles/ProjectPage.module.css"
import NotFoundPage from "../../NotFound/NotFoundPage";
import PollResultPageView from "./PollResultPageView";

const PollResultPage = () => {
    const {pollId} = useParams();
    return (
        <Container className={styles.projectPage}>
            <>
            { (pollId)
                ? <PollResultPageView pollId={pollId}/>
                : <NotFoundPage/>
            }
            </>
        </Container>
    );
};

export default PollResultPage;