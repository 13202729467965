import { Form } from "react-bootstrap";
import { FieldError, RegisterOptions, UseFormRegister } from "react-hook-form";

interface SelectInputFieldProps {
    name: string,
    label: string,
    register: UseFormRegister<any>,
    registerOptions?: RegisterOptions,
    error?: FieldError,
    [x: string]: any,
}

const SelectInputField = ({name, label, register, registerOptions, error, ...props}: SelectInputFieldProps) => {
    return (
        <Form.Group className="mb-3" controlId={name + "-input"}>
            <Form.Label>{label}</Form.Label>
            <Form.Select 
                aria-label="Default select"
                {...register(name, registerOptions)} 
                isInvalid={!!error}
                >
                {props.items.map((item: any) => {
                    return <option value={item.id}>{item.name}</option>
                })}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
                {error?.message}
            </Form.Control.Feedback>
            
        </Form.Group>
    );
}

export default SelectInputField;