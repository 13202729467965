import { Card } from "react-bootstrap";
import { PollTemplate as PollTemplateModel } from "../services/models/poll_template";
import styles from "../assets/styles/Project.module.css";
import styleUtils from "../assets/styles/utils.module.css";

interface StatisticPollTemplateProps {
    pollTemplate: PollTemplateModel,
    onPollTemplateClicked: (pollTemplate: PollTemplateModel) => void,
    className?: string,
};

const StatisticPollTemplate = ({ pollTemplate, onPollTemplateClicked, className }: StatisticPollTemplateProps) => {
    const {
        title,
    } = pollTemplate;

    return (
        <Card
            className={`${styles.projectCard} ${className}`}
            onClick={() => onPollTemplateClicked(pollTemplate)}
            >
            <Card.Body className={styles.cardBody}>
                <Card.Title className={styleUtils.flexCenterSpaceBetween}>
                    {title}
                </Card.Title>
            </Card.Body>
        </Card>
    );
};

export default StatisticPollTemplate;