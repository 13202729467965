import { Container, Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Administrator } from "../../services/models/administrator";
import NavBarLoggedInView from "./NavBarLoggedInView";
import NavBarLoggedOutView from "./NavBarLoggedOutView";
import { SubUser } from "../../services/models/sub_user";

interface NavBarProps {
    loggedInUser: Administrator | SubUser | null
    onLogoutSuccessful: () => void,
}

const NavBar = ({loggedInUser, onLogoutSuccessful}: NavBarProps) => {
    return (
        <Navbar bg="primary" variant="dark" expand="md">
            <Container>
                <Navbar.Brand as={Link} to="/">
                    "НМИЦ ИТ"
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="main-navbar" />
                <Navbar.Collapse id="main-navbar">
                    <Nav className="ms-auto">
                            { loggedInUser
                                ? <NavBarLoggedInView user={loggedInUser} onLogoutSuccessful={onLogoutSuccessful} />
                                : <NavBarLoggedOutView/>
                            }
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default NavBar;